const KEY = 'elfs_admin_user'

const storeData = (value) => {
  const jsonValue = JSON.stringify(value)
  window.localStorage.setItem(KEY, jsonValue)
}

const getAllData = () => {
  try {
    return JSON.parse(window.localStorage.getItem(KEY))
  } catch (err) {
    return null
  }
}

const getData = (itemKey) => {
  try {
    const data = getAllData()
    if (data) {
      return data[itemKey]
    }
    return null
  } catch (e) {
    // error reading value
    return null
  }
}

const removeDate = () => window.localStorage.removeItem(KEY)

export { storeData, removeDate, getAllData, getData }
