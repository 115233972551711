export default {
  dob: 'musicAssessments.socioDemographic.dob',
  preferredName: 'musicAssessments.socioDemographic.preferredName',
  countryBorn: 'musicAssessments.background.countryBorn',
  languageSpeakAtHome: 'musicAssessments.background.languageSpeakAtHome',
  liveAge15_30: 'musicAssessments.background.liveAge15_30',
  favouriteGenre: (participant) =>
    participant?.musicAssessments?.favouriteGenre.join(),
  musicDontLike: (participant) =>
    participant?.musicAssessments?.musicDontLike.join(),
  favouriteArtists: (participant) =>
    participant?.musicAssessments?.favouriteArtists
      .map((artist) => artist.name)
      .join(),
  favouriteSongs: (participant) =>
    participant?.musicAssessments?.favouriteSongs
      .map((song) => song.name)
      .join(),
  musicForBoth: (participant) =>
    participant?.musicAssessments?.musicForBoth.map((song) => song.name).join(),
}
