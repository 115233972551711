import { getData } from '@utils/storage'

const getAccessToken = () => {
  const tokens = getData('tokens')
  const accessToken = tokens?.access?.token
  if (accessToken) {
    return 'Bearer ' + accessToken
  } else {
    return null
  }
}

export { getAccessToken }
