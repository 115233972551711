import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import PropTypes from 'prop-types'

export const LoadingIndicator = ({ size = 100 }) => (
  <LoadingOutlined style={{ fontSize: size }} spin />
)

LoadingIndicator.propTypes = {
  size: PropTypes.number,
}
