import { combineReducers } from 'redux'
import user from './user.reducers'

// Add your new reducer here
const reducers = {
  users: user,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
