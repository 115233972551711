export default {
  manageBehavioursShouting: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.manageBehavioursShouting
        ?.selected
        ? 'Yes'
        : 'No'
    }`,
  manageBehavioursRepetitiveMovements: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.manageBehavioursShouting
        ?.selected
        ? 'Yes'
        : 'No'
    }`,
  manageBehavioursRepetitiveWords: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.manageBehavioursShouting
        ?.selected
        ? 'Yes'
        : 'No'
    }`,
  manageBehavioursPhysicalAggression: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed
        ?.manageBehavioursPhysicalAggression?.selected
        ? 'Yes'
        : 'No'
    }`,
  activateSelf: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.activateSelf?.selected
        ? 'Yes'
        : 'No'
    }`,
  relaxSelf: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.relaxSelf?.selected
        ? 'Yes'
        : 'No'
    }`,
  cooperateInTasks: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.cooperateInTasks?.selected
        ? 'Yes'
        : 'No'
    }`,
  walkingToCar: (participant) =>
    `${
      participant?.needsAssessments?.carersNeed?.walkingToCar?.selected
        ? 'Yes'
        : 'No'
    }`,
  settlingIntoBed: (participant) =>
    `${
      participant?.needsAssessments.carersNeed.settlingIntoBed.selected
        ? 'Yes'
        : 'No'
    }`,
  orientToEnvironment: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.orientToEnvironment?.selected
        ? 'Yes'
        : 'No'
    }`,
  feelSafe: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.feelSafe?.selected
        ? 'Yes'
        : 'No'
    }`,
  reduceIsolation: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.reduceIsolation?.selected
        ? 'Yes'
        : 'No'
    }`,
  reinforceSelfOfSelf: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.reinforceSelfOfSelf?.selected
        ? 'Yes'
        : 'No'
    }`,
  validateIdentity: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.validateIdentity?.selected
        ? 'Yes'
        : 'No'
    }`,
  reduceDepression: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.reduceDepression?.selected
        ? 'Yes'
        : 'No'
    }`,
  reduceAnxiety: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.reduceAnxiety?.selected
        ? 'Yes'
        : 'No'
    }`,
  betterRelaxation: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.betterRelaxation?.selected
        ? 'Yes'
        : 'No'
    }`,
  feelConnected: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.feelConnected?.selected
        ? 'Yes'
        : 'No'
    }`,
  physicalFitnessAndWellBeing: (participant) =>
    `${
      participant?.needsAssessments?.needsOfPwd?.physicalFitnessAndWellBeing
        ?.selected
        ? 'Yes'
        : 'No'
    }`,

  improveVerbalMusicalCommunication: (participant) =>
    `${
      participant?.relationalNeeds?.needsOfPwd
        ?.improveVerbalMusicalCommunication?.selected
        ? 'Yes'
        : 'No'
    }`,
  relationshipQuality: (participant) =>
    `${
      participant?.relationalNeeds?.needsOfPwd?.relationshipQuality?.selected
        ? 'Yes'
        : 'No'
    }`,
  reciprocalInteractions: (participant) =>
    `${
      participant?.relationalNeeds?.needsOfPwd?.reciprocalInteractions?.selected
        ? 'Yes'
        : 'No'
    }`,
  connectCarerFamily: (participant) =>
    `${
      participant?.relationalNeeds?.needsOfPwd?.connectCarerFamily?.selected
        ? 'Yes'
        : 'No'
    }`,
}
