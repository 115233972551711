import musicAssessments from '@data/csvHeaders/musicAssessments.json'
import needsAssessments from '@data/csvHeaders/needsAssessments.json'
import moodsAssessments from '@data/csvHeaders/moodsAssessments.json'
import spotifyMetadata from '@data/csvHeaders/spotifyMetadata.json'

import musicAssessmentsMappings from '@data/csvHeaderValueMappings/musicAssessments'
import needsAssessmentsMappings from '@data/csvHeaderValueMappings/needsAssessments'
import moodsAssessmentsMappings from '@data/csvHeaderValueMappings/moodsAssessments'
import spotifyMetadataMappings from '@data/csvHeaderValueMappings/spotifyMetadata'

const CSV_HEADERS = {
  name: 'Name',
  email: 'Email',
  carerName: 'Carer Name',
  pwdName: 'Person With Dementia Name',
  ...musicAssessments,
  ...needsAssessments,
  ...moodsAssessments,
  ...spotifyMetadata,
}

const CSV_HEADER_VALUE_MAPPINGS = {
  name: 'name',
  email: 'email',
  carerName: 'carerName',
  pwdName: 'pwdName',
  ...musicAssessmentsMappings,
  ...needsAssessmentsMappings,
  ...moodsAssessmentsMappings,
  ...spotifyMetadataMappings,
}

export { CSV_HEADERS, CSV_HEADER_VALUE_MAPPINGS }
