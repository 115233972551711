import React, { useState } from 'react'
import { Button, notification } from 'antd'
import { get } from 'lodash'
import Papa from 'papaparse'

import { Header, Content } from './styles'
import { getAllParticipants } from '@api/participant.api'
import { getHistoryInCsv, getQuestionsInCsv } from '@api/data-export.api'
import { CSV_HEADERS, CSV_HEADER_VALUE_MAPPINGS } from '@src/constants/csv'

const DashboardContainer = () => {
  const [isGenerating, setIsGenerating] = useState(false)

  const generateCsv = async () => {
    const participants = await getAllParticipants()
    const header = Object.values(CSV_HEADERS)
    const values = participants.map((participant) => {
      return Object.keys(CSV_HEADER_VALUE_MAPPINGS).map((key) => {
        if (typeof CSV_HEADER_VALUE_MAPPINGS[key] === 'function') {
          return CSV_HEADER_VALUE_MAPPINGS[key](participant)
        }
        return get(participant, CSV_HEADER_VALUE_MAPPINGS[key])
      })
    })
    const data = [header, ...values]
    const test = Papa.unparse(data)

    var encodedUri = encodeURI(test)
    return 'data:text/csv;charset=utf-8,' + encodedUri
  }

  const downloadCsv = async () => {
    setIsGenerating(true)
    try {
      const csvContent = await generateCsv()
      // generate link
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('target', '_blank')
      link.setAttribute('download', 'participants.csv')
      link.click()
    } catch (err) {
      notification.error({
        message: err.message,
        style: {
          width: 600,
        },
      })
    }
    setIsGenerating(false)
  }

  const downloadHistory = () => {
    getHistoryInCsv().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'history.xlsx')
      document.body.appendChild(link)
      link.click()
    })
  }

  const downloadQuestions = () => {
    getQuestionsInCsv().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'questions.xlsx')
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <>
      <Header>Dashboard</Header>
      <Content>
        <div>
          <Button type="primary" loading={isGenerating} onClick={downloadCsv}>
            Download participants info
          </Button>
        </div>
        <div>
          <Button type="primary" onClick={downloadHistory}>
            Download participants info history
          </Button>
        </div>
        <div>
          <Button type="primary" onClick={downloadQuestions}>
            Download Questions
          </Button>
        </div>
      </Content>
    </>
  )
}

export default DashboardContainer
