import { USER } from '@store/action-types'
import { user as authApi } from '@api/index'
import { removeDate, storeData } from '@utils/storage'

export const onUserLogin = (values) => {
  return {
    type: USER.SET_USER,
    async payload() {
      const res = await authApi.login(values)
      storeData(res)
      return res
    },
  }
}

export const setUserDetailsFromLocalStorage = (data) => (dispatch) => {
  return dispatch({
    type: USER.SET_USER,
    payload: data,
  })
}

export const logoutHandler = () => (dispatch) => {
  removeDate()
  return dispatch({
    type: USER.UNSET_USER,
    payload: null,
  })
}
