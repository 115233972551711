import styled from 'styled-components'

const Header = styled.div`
  padding: 0.8rem 0;
  margin-bottom: 0.4rem;
  font-size: 1.5rem;
  font-weight: 500;
  flex: 0 0;
  text-align: left;
`

const Content = styled.div`
  div {
    margin-bottom: 12px;
  }
`

export { Header, Content }
