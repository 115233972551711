import React from 'react'
import { notification } from 'antd'
import { get } from 'lodash'

import { storeData } from '@utils/storage'
import {
  logoutHandler,
  setUserDetailsFromLocalStorage,
} from '@actions/user.actions'
import store from '@store/index'
import { getAccessToken } from './utils'
import API from './client'
import auth from './user.api'

/**
 * Assign access token to request header
 */
API.interceptors.request.use((config = {}) => {
  const token = getAccessToken()
  config.headers.Authorization = token
  return config
})

/**
 * Http response interceptor
 * - response formatting
 * - Refresh the access token or force logout if refresh token expired
 */
API.interceptors.response.use(
  // post process
  (response) => ({
    data: response.data,
    message: get(response, 'data.message', response.statusText),
    status: get(response, 'data.status', response.status),
    success: true,
  }),
  // error handler
  async (error) => {
    const { status, data } = error?.response

    if (status === 401) {
      if (error.config && !error.config.__isRetryRequest) {
        try {
          error.config.__isRetryRequest = true
          const { data } = await auth.refreshToken()
          storeData(data)
          store().dispatch(setUserDetailsFromLocalStorage(data))
          error.config.headers.Authorization = data.tokens.access.token
          return API.request(error.config)
        } catch (err) {
          // pass
        }
      }
      store().dispatch(logoutHandler())
      notification.error({
        message: 'Unauthorized Access',
        description: 'You will be logged out in 4 seconds',
        style: {
          width: 600,
        },
      })
      await new Promise((r) => setTimeout(r, 4000))
      window.location.reload(false)
    } else {
      notification.error({
        message: 'Error',
        description: <pre>{JSON.stringify(data, null, 2)}</pre>,
        style: {
          width: 600,
        },
      })
    }
    return Promise.reject(error)
  }
)
