import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import LoginContainer from '@containers/login'
import DashboardContainer from '@containers/dashboard'
// import ModulesContainer from '@containers/modules'
import LogoutContainer from '@containers/logout'
// import ROUTES from '@constants/routes'

function AppRoutes({ userBio }) {
  if (userBio) {
    return (
      <Switch>
        <Route path="/logout" component={LogoutContainer} />
        {/* <Route path={ROUTES.MODULES} component={ModulesContainer} /> */}
        {/*<Route path="/surveys" component={SurveysContainer} />*/}
        <Route path="/" component={DashboardContainer} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/" component={LoginContainer} />
      </Switch>
    )
  }
}

AppRoutes.propTypes = {
  userBio: PropTypes.shape({
    name: PropTypes.string,
  }),
}

const mapStateToProps = ({ users }) => {
  return {
    userBio: users.bio,
  }
}

export default connect(mapStateToProps)(AppRoutes)
