import React from 'react'
import { Form, Input, Button, Typography } from 'antd'
import styled from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { LoginOutlined } from '@ant-design/icons'
import { onUserLogin } from '@actions/user.actions'

const { Title } = Typography

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
}

const LoginContainer = ({ onUserLogin, loading }) => {
  const onFinish = (values) => {
    onUserLogin(values)
  }

  return (
    <ParentCenteredDiv>
      {/*<Title level={1}>ELFS</Title>*/}
      <LoginOutlined
        style={{
          fontSize: 30,
          borderRadius: 30,
          backgroundColor: 'red',
          color: 'white',
          padding: 10,
        }}
      />
      <Title level={5}>Sign In</Title>
      <ChildCenteredDiv>
        <Form {...layout} name={'login_form'} onFinish={onFinish}>
          <Form.Item
            label="Email Id"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              disabled={loading}
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </ChildCenteredDiv>
    </ParentCenteredDiv>
  )
}

const ParentCenteredDiv = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ChildCenteredDiv = styled.div`
  width: 600px;
  //border: 1px solid green;
  padding: 30px;
  border-radius: 20px;
`

const mapStateToProps = ({ users }) => ({
  loading: users.loading,
})

const mapDispatchToProps = {
  onUserLogin,
}

LoginContainer.propTypes = {
  onUserLogin: PropTypes.func,
  loading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
