import EP from '@src/api/endpoints'
import API from '@src/api/client'

const getHistoryInCsv = () =>
  API.get(EP.DOWNLOAD_HISTORY, { responseType: 'blob' })

const getQuestionsInCsv = () =>
  API.get(EP.DOWNLOAD_QUESTIONS, { responseType: 'blob' })

export { getHistoryInCsv, getQuestionsInCsv }
