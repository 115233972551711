import { notification } from 'antd'
import { get } from 'lodash'

import EP from '@src/api/endpoints'
import { getData } from '@utils/storage'
import { createAPIInstance } from '@src/api/client'

const API = createAPIInstance()

API.interceptors.response.use(
  // post process
  (response) => ({
    data: response.data,
    message: get(response, 'data.message', response.statusText),
    status: get(response, 'data.status', response.status),
    success: true,
  }),
  // error handler
  async (error) => {
    const data = error?.response?.data
    if (data?.message) {
      notification.error({
        message: data.message,
      })
    } else {
      notification.error({
        message: data?.message,
        description: JSON.stringify(data, null, 2),
        style: {
          width: 600,
        },
      })
    }
    return Promise.reject(error)
  }
)
const login = (data) => API.post(EP.LOGIN(), data).then((res) => res.data)

const refreshToken = () => {
  const refreshToken = getData('tokens')?.refresh.token
  return createAPIInstance().post(EP.REFRESH_TOKEN(), { refreshToken })
}

export default { login, refreshToken }
