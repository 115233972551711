export default {
  playlists: (participant) => {
    const playlists = participant?.spotifyMetadata?.playlists
    if (!playlists) {
      return ''
    }
    return playlists.map(({ name }) => name).join()
  },
  likedSongs: (participant) => {
    const playlists = participant?.spotifyMetadata?.playlists
    if (!playlists) {
      return ''
    }
    return playlists
      .map((playlist) => {
        return `[${playlist.likedSongs.map(({ name }) => name).join()}]`
      })
      .join()
  },
  likedSongsResponses: (participant) => {
    const playlists = participant?.spotifyMetadata?.playlists
    if (!playlists) {
      return ''
    }
    return playlists
      .map(({ likedSongs }) => {
        return `[${likedSongs
          .map(({ responses }) => `[${responses.join()}]`)
          .join()}]`
      })
      .join()
  },
  dislikedSongs: (participant) => {
    const playlists = participant?.spotifyMetadata?.playlists
    if (!playlists) {
      return ''
    }
    return playlists
      .map(({ dislikedSongs }) => {
        return `[${dislikedSongs.map(({ name }) => name).join()}]`
      })
      .join()
  },
  dislikedSongsResponses: (participant) => {
    const playlists = participant?.spotifyMetadata?.playlists
    if (!playlists) {
      return ''
    }
    return playlists
      .map(({ dislikedSongs }) => {
        return `[${dislikedSongs
          .map(({ responses }) => `[${responses.join()}]`)
          .join()}]`
      })
      .join()
  },
}
