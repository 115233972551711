export default {
  carers: (participant) => {
    const carers = participant?.moodsAssessments?.carers
    if (!carers) {
      return ''
    }
    return carers.map(({ responses }) => `[${responses.join()}]`).join()
  },
  pwd: (participant) => {
    const pwd = participant?.moodsAssessments?.pwd
    if (!pwd) {
      return ''
    }
    return pwd.map(({ responses }) => `[${responses.join()}]`).join()
  },
}
