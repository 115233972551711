import axios from 'axios'

const baseURL = '/api'

const API = axios.create({
  baseURL,
})

const createAPIInstance = (config) =>
  axios.create({
    baseURL,
    ...config,
  })

export { createAPIInstance }
export default API
